import React, { useContext, useEffect } from "react"
import "./header.component.scoped.scss"
import { icons } from "../../statics/icons"
import Dropdown from "react-bootstrap/Dropdown"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { history } from "react-router-guard"
import { GlobalContext, HomeContext } from "../../store"
import { LocalStorageService } from "../../services/local-storage/local-storage"
import { SessionStorageService } from "../../services/session-storage/session-storage"
import { Button } from "@material-ui/core"

export default function HeaderComponent(props: any) {
  const localStorageService: LocalStorageService = new LocalStorageService();
  const sessionStorageService: SessionStorageService = new SessionStorageService();

  const { setLang, lang, translation, languages } = useContext(GlobalContext);
  const { activeStep } = useContext(HomeContext)

  let routeName = "/"
  if (history.location.pathname !== "/temp") {
    routeName = history.location.pathname
  }

  const selectLang = (newLang: string) => {
    if (lang === newLang) return
    localStorageService.setData("lang", newLang)
    setLang(newLang);
    history.replace("/temp");
    setTimeout(() => {
      history.replace(routeName)
    })
  }

  // scroll to to on history change
  useEffect(() => {
    const scrollable: any = document.querySelector(".main-wrapper")
    const unlisten = history.listen(() => {
      if (
        history.location.pathname !== "/case" &&
        history.location.pathname !== "/temp"
      ) {
        sessionStorageService.removeData("caseInternalId")
      }
      if (scrollable) {
        scrollable.scrollTo(0, 0)
      }
    })
    return () => {
      unlisten()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = (route: string = "/") => {
    history.push(route)
  }

  return (
    <header className="main-header">
      <div className="header-content">
        <div className="left-content">
          <div className="main-logo">
            <img
              src={icons.logo}
              alt="logo"
              className=""
              onClick={navigate.bind(null, "/")}
            />
          </div>
          <div className="header-button">
            {!routeName.includes("case") &&
              activeStep === 0 &&
              !routeName.includes("track") && (
                <Button
                  startIcon={<img src={icons.trackReportLight} alt="" />}
                  onClick={navigate.bind(null, "/track")}
                >
                  {translation.create['track-case']}
                </Button>
              )}
            {routeName.includes("case") && (
              <Button
                startIcon={<img src={icons.addLight} alt="" />}
                onClick={navigate.bind(null, "/")}
              >
                {translation.create['new-case']}
              </Button>
            )}
          </div>
        </div>
        {languages.length > 1 && <div className="right-content">
          {/* <div className="header-item help">
            <img src={icons.help} alt="help" className="" />
          </div> */}
          <div className="header-item language">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
                className="language-switch-btn"
              >
                <img src={icons[lang]} alt="" />
                <div className="language-name">{lang.toUpperCase()}</div>
                <ExpandMoreIcon className="expand-more" />
                <ExpandLessIcon className="expand-less" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {languages.map((item: any) => {
                  return (
                    <Dropdown.Item onClick={selectLang.bind(null, item.name)}>
                      <img src={icons[item.name]} alt={item.name} title={item.name} />
                      {item.name}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>}
      </div>
      {props.children}
    </header>
  )
}
