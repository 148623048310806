import React, { useContext, useEffect, useRef, useState } from 'react'
import { HomeContext } from '../../store'
import CreateCaseStep1 from '../create-case-step-1/create-case-step-1.component'
import CreateCaseStep2 from '../create-case-step-2/create-case-step-2.component'
import CreateCaseStep3 from '../create-case-step-3/create-case-step-3.component'
import CreateCaseStep4 from '../create-case-step-4/create-case-step-4.component'
import CreateCaseStepper from '../create-case-stepper/create-case-stepper.component'
import { CasesService } from '../../services/cases/cases.service'
import './home-content.component.scoped.scss'
import FilesDropZone from '../files-drop-zone/files-drop-zone.component'

export default function HomeContent() {
  const {
    activeStep,
    setBranches,
    setLands,
    setCategories,
    setAmounts,
    setRelations,
  } = useContext(HomeContext);

  const casesService: CasesService = new CasesService()
  const myRef: any = useRef()
  const [files, setFiles] = useState([])

  const fetchData = () => {
    casesService
      .listBranches()
      .then((branchesRes: any) => setBranches(branchesRes))
    casesService.listLands().then((landsRes: any) => setLands(landsRes))
    casesService.listCategories().then((res) => setCategories(res))
    casesService.listCaseAmounts().then((res) => setAmounts(res))
    casesService.listCaseRelations().then((res) => setRelations(res))
  }
  const scrollTop = () => myRef.current.scrollIntoView()

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="home-content" ref={myRef}>
      <CreateCaseStepper />
      {activeStep === 0 && <CreateCaseStep1 scrollTop={scrollTop} />}
      {activeStep === 1 && (
        <FilesDropZone onDropFiles={setFiles} disabled={false}>
          <CreateCaseStep2 droppedFiles={files} scrollTop={scrollTop} />
        </FilesDropZone>
      )}
      {activeStep === 2 && <CreateCaseStep3 />}
      {activeStep === 3 && <CreateCaseStep4 />}
    </div>
  )
}
