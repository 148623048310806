import axios from "axios";
import { LocalStorageService } from "../services/local-storage/local-storage";
import { history } from "react-router-guard";

export const setupInterceptors = () => {
  const localStorageService: LocalStorageService = new LocalStorageService();
  axios.interceptors.request.use(req => {
    // Do something before request is sent
    req.headers.lang = localStorageService.getData("lang") || 'de';
    return req;
  }, err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    res => res,
    err => {
      // handle keycloack token expiration.
      if (err.response.status === 401 || err.response.status === 403) {
        localStorageService.clearAll();
        history.push("/");
      }
      throw new Error(err.response.data);
    }
  )

}