import React, { createContext, useReducer } from "react";
import GlobalReducer from "../reducers/global.reducer";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { Translation } from "../../types/translation";
const localStorageService: LocalStorageService = new LocalStorageService();

const initialState: any = {
  lang: localStorageService.getData("lang") || 'de',
  translation: new Translation(),
  languages: [],
  configuration: {}
};

export const GlobalContext = createContext(initialState);
export const GlobalProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState);

  function setLang(lang: string) {
    dispatch({
      type: "SET_LANG",
      payload: { lang }
    });
  }

  function setTranslation(translation: Translation) {
    dispatch({
      type: "SET_TRANSLATION",
      payload: { translation },
    });
  }

  function setLanguages(languages: []) {
    dispatch({
      type: "SET_LANGUAGES",
      payload: { languages }
    });
  }

  function setConfiguration(configuration: {}) {
    dispatch({
      type: "SET_CONFIGURATION",
      payload: { configuration }
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        setLang,
        setTranslation,
        setLanguages,
        setConfiguration
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};