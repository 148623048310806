import packageJson from "../../package.json";
// const URL = window.location.host.split('.');
// const subDomain = URL.shift();
// const domain = URL.join('.');

export const localEnvironmentConfig = {
  // serverUrl: `https://staging-staging-api.compliance2b.com/`,
  serverUrl: `http://localhost:8000/`,
  clientPrefix: "GRC",
  storagePrefix: "live",
  appVersion: packageJson.version,
};
