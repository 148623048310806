import React, { useContext, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import { useTranslation } from "react-i18next"
import { icons } from "../../statics/icons";
import { GlobalContext, HomeContext } from "../../store";
import "./create-case-step-1.component.scoped.scss";

export default function CreateCaseStep1(props: any) {
  // const [t] = useTranslation()
  const { setActiveStep, caseForm, setCaseForm, branches, lands } = useContext(
    HomeContext
  );
  const { translation, configuration } = useContext(GlobalContext);

  const handleChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;
    const obj: any = {};
    obj[name] = value;
    setCaseForm({ ...caseForm, ...obj });
  };

  const selectBranch = (event: any) => {
    const branch = event.target.value;
    setCaseForm({ ...caseForm, branch, branchId: branch.id });
  };

  const selectLand = () => {
    if (!configuration.country_visibility && lands.length) {
      const value = lands[0].id;
      const name = "originalLandId";
      const obj: any = {};
      obj[name] = value;
      setCaseForm({ ...caseForm, ...obj });
    }
  };

  useEffect(() => {
    selectLand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lands]);

  return (
    <div className="theme-container step-container">
      <div className="flex-column ">
        <div className="welcome-title mb-4">
          {translation.create["welcome to the reporting channel"]}
        </div>
        <div className="text-step-1 no-gutters">
          <p
            className="welcome-text"
            dangerouslySetInnerHTML={{
              __html: translation.create["welcome-title-one"],
            }}
          ></p>
          <p
            className="welcome-text"
            dangerouslySetInnerHTML={{
              __html: translation.create["welcome-title-two"],
            }}
          ></p>
          <p
            className="welcome-text"
            dangerouslySetInnerHTML={{
              __html: translation.create["welcome-title-three"],
            }}
          ></p>
          <p
            className="welcome-text"
            dangerouslySetInnerHTML={{
              __html: translation.create["welcome-title-four"],
            }}
          ></p>
        </div>
      </div>
      <img
        src={
          configuration.reporter_cover_url
            ? configuration.reporter_cover_url
            : "step-1.png"
        }
        alt=""
        className="step-1-img"
      />
      <div className="drop-downs d-flex flex-column">
        <div className="main-question mb-2">
          {translation.create["where did the incident take place?"]}
        </div>
        <div className="row no-gutters">
          <div className="first-select col-xl-5 col-md-5">
            <FormControl
              className="select-input select-grey"
              style={{ width: "calc(100% - 0px)" }}
            >
              <InputLabel id="branch">
                {translation.create["affected company"]}
              </InputLabel>
              <Select
                labelId="branch"
                id="branch-select"
                name="branchId"
                value={caseForm.branch}
                onChange={selectBranch}
                IconComponent={ExpandMoreIcon}
              >
                {branches.map((item: any, index: any) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          {configuration.country_visibility && (
            <div className="second-select col-xl-4 col-md-5">
              <FormControl
                className="select-input select-grey"
                style={{ width: "calc(100% - 0px)" }}
              >
                <InputLabel id="land">
                  {translation.create["country"]}
                </InputLabel>
                <Select
                  labelId="land"
                  id="land-select"
                  name="originalLandId"
                  value={caseForm.originalLandId}
                  onChange={handleChange}
                  IconComponent={ExpandMoreIcon}
                >
                  {lands.map((item: any, index: any) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
          <div
            className={`continue-btn ${
              !configuration.country_visibility ? "second-select" : ""
            }`}
          >
            <Button
              className="btn-primary-theme btn-next"
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveStep(1);
                props.scrollTop();
              }}
              disabled={!caseForm.branchId || !caseForm.originalLandId}
            >
              <ArrowForwardIcon /> {translation.create["enter report"]}
            </Button>
          </div>
        </div>

        <div className="hint-wrapper mt-2">
          <div className="mr-1">
            <img src={icons.infoDark} alt="" />
          </div>
          <div
            className="hint"
            dangerouslySetInnerHTML={{
              __html:
                translation.create[
                  "please note that your information will only be forwarded to the selected company"
                ],
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
