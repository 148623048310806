// import i18n from "i18next";
// import ChainedBackend from "i18next-chained-backend";
// import HttpBackend from "i18next-http-backend";
// import resourcesToBackend from "i18next-resources-to-backend";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { LocalStorageService } from "../services/local-storage/local-storage";
// import { LangService } from '../services/lang/lang.service';
const localStorageService: LocalStorageService = new LocalStorageService();
// const langService: LangService = new LangService();

// const getTranslation = async () => {
//   const translation = await langService.getTranslation();
//   console.log(translation);
//   return translation;
// }


i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false
    },
    lng: localStorageService.getData("lang") || 'de',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    }
  });

// i18n.use(ChainedBackend).init({
//   backend: {
//     backends: [
//       HttpBackend, // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
//       resourcesToBackend(async (language, namespace, callback) => {
//         await langService.getTranslation()
//           .then((res: any) => { // with dynamic import, you have to use the "default" key of the module ( https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#importing_defaults )
//             console.log(res)
//             callback(null, JSON.stringify(res))
//           })
//           .catch((error) => {
//             callback(error, '')
//           })
//       })
//     ],
//     backendOptions: [{
//       loadPath: '/locales/en/translation.json',
//       crossDomain: true
//     }]
//   }
// })

export default i18n;