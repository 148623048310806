import React, { useContext, useState } from "react";
import "./communication-timeline.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { TimelineStatusesCenteredComponent } from "../timeline-statuses-centered/timeline-statuses-centered.component";
import { TimelineCommentComponent } from "../timeline-comment/timeline-comment.component";
import { CaseContext } from "../../store";
import { CaseComments } from "../../types/case-comments";
import { Comment } from "../../types/comment";
import { icons } from "../../statics/icons";

export const CommunicationTimelineComponent = (props: any) => {
  const { communicationsLoading, setCommunicationsLoading, caseComments, caseDetails, caseStatuses } = useContext(CaseContext);
  const [t] = useTranslation();
  const [timelineStatuses, setTimelineStatuses] = useState(caseStatuses);
  const maxComments = 5;

  const isActiveStatus = (status: string): boolean => {
    return caseDetails.status === status;
  };

  const statusPosition = (status: string): string => {
    return isActiveStatus(status) ? "right" : "left";
  };

  const isAnonymousComment = (comment: Comment): boolean => {
    return comment.user ? false : true;
  };

  const commentPosition = (comment: Comment): string => {
    return isAnonymousComment(comment) ? "left" : "right";
  };

  const userIcon = (comment: Comment): string => {
    return isAnonymousComment(comment) ? (icons.userImgLight) : (icons.usersImg);
  };

  const expandStatusComments = (expand: boolean, index: number): void => {
    const timelineStatusesCopy = [...timelineStatuses];
    timelineStatusesCopy[index].expand = expand;
    setTimelineStatuses(timelineStatusesCopy);
  }

  const showAllComments = (showAll: boolean, index: number): void => {
    setCommunicationsLoading(true)
    const timelineStatusesCopy = [...timelineStatuses];
    timelineStatusesCopy[index].showAll = showAll;
    setTimelineStatuses(timelineStatusesCopy);
    setTimeout(() => {
      setCommunicationsLoading(false);
    }, 300)
  }

  return (
    <div className="timeline">
      <div className="timeline-items">
        {caseComments.map((caseComment: CaseComments, index: any) => {
          const comments: any[] = (!timelineStatuses[index]?.expand && !communicationsLoading) ? [] : timelineStatuses[index]?.showAll ? caseComment.comments : caseComment.comments.slice(Math.max(caseComment.comments.length - maxComments, 0));
          return (
            <>
              <TimelineStatusesCenteredComponent
                key={index}
                position={statusPosition(caseComment.status)}
                activeStatus={isActiveStatus(caseComment.status)}
                icon={timelineStatuses[index]?.icon}
                status={caseComment.status}
                lastStatus={index === timelineStatuses.length - 1}
                total={caseComment.total}
                statusCount={`(${caseComment.total} ${+caseComment.total === 1 ? t('case.comment') : t('case.comments')})`}
                date={caseComment.date}
                loading={communicationsLoading}
                expand={timelineStatuses[index]?.expand && caseComment.comments.length > 0}
                handleExpand={(value: boolean) => expandStatusComments(value, index)}
                showAll={timelineStatuses[index]?.showAll}
                handleShowAll={(value: boolean) => showAllComments(value, index)}
                maxComments={maxComments}
                dimmed={timelineStatuses[index]?.dimmed}
              />
              {comments.map((comment: Comment, commentIndex: any) => {
                return (
                  <TimelineCommentComponent
                    key={comment.id}
                    id={comment.id}
                    files={comment.files || []}
                    position={commentPosition(comment)}
                    icon={userIcon(comment)}
                    comment={comment.content}
                    date={comment.createdAt}
                    user={comment.user}
                    loading={communicationsLoading}
                    tab="communication"
                  />
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
};
